import React from "react"
import { graphql } from "gatsby"
import { linkResolver } from "../prismic/linkResolver"
import Wrapper from "../components/Wrapper"
import Grid from "../components/Grid"
import Img from "../components/CustomImg"
import Button from "../components/Button"
import AspectRatio from "../components/AspectRatio"
import { Link } from "gatsby"

const Raadgivning = ({ data }) => {
  const [showMoreImages, setShowMoreImages] = React.useState(false)
  const [showTimes, setShowTimes] = React.useState(false)
  const [showForm, setShowForm] = React.useState(false)

  const moreImagesClick = () => {
    setShowMoreImages(true)
  }

  const timesClick = () => {
    setShowTimes(true)
  }

  const timesRemoveClick = () => {
    setShowTimes(false)
  }

  const formClick = () => {
    console.log("show")
    setShowForm(true)
  }

  return (
    <>
      <div className="slice feature">
        <Wrapper>
          <div className="grid" style={{ alignItems: "normal" }}>
            <div className="text-col" style={{ marginTop: "40px" }}>
              <div className="paragraph-max-width">
                <h2 className="fs--s mb--xs tt--u co--light">
                  Mangler du hjælp?
                </h2>
                <h3 className="fs--m mb--s lh--s">Stylerådgivning</h3>
                <div className="co--light lh--m mb--l article">
                  Vi sidder klar til at hjælpe dig og rådgive dig, så du kan få
                  de flotteste nye gardiner. Stylerådgivning er uforpligtende og
                  gratis. Du skal blot klikke på knappen "Book stylerådgivning"
                  og udfylde formularen, så vil en af vores konsulenter tage fat
                  i dig.
                  <br />
                  {!showForm && (
                    <div onClick={formClick} style={{ marginTop: "30px" }}>
                      <Button
                        classList="consultancy-button"
                        icon="chevron-right"
                        text="Book Facetime/telefonmøde"
                      />
                    </div>
                  )}
                </div>
                {showForm && (
                  <form
                    className="contact-form consultancy-form"
                    acceptCharset="UTF-8"
                    action="https://www.formbackend.com/f/89312fd6b14483f8"
                    method="POST"
                    encType="multipart/form-data"
                  >
                    <div>
                      <label htmlFor="name">Navn</label>
                      <input type="text" id="name" name="name" required />
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1, paddingRight: "20px" }}>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />
                      </div>
                      <div style={{ flex: 1 }}>
                        <label htmlFor="telephone">Telefonnummer</label>
                        <input
                          type="text"
                          id="email"
                          name="telephone"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="contactType">
                        Hvordan ønsker du at blive kontaktet?
                      </label>
                      <input
                        type="radio"
                        className="radio"
                        id="contactType"
                        name="contactType"
                        value="Facetime"
                        onClick={timesClick}
                      />{" "}
                      Facetime &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        className="radio"
                        id="contactType"
                        name="contactType"
                        value="Facetime"
                        onClick={timesClick}
                      />{" "}
                      Telefon &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        className="radio"
                        id="contactType"
                        name="contactType"
                        value="email"
                        onClick={timesRemoveClick}
                      />{" "}
                      Email
                    </div>
                    {showTimes && (
                      <div>
                        <label htmlFor="time">
                          Hvornår ønsker du at blive ringet op?
                        </label>
                        <input
                          type="radio"
                          className="radio"
                          id="time"
                          name="time"
                          value="tuesday"
                        />{" "}
                        Tirsdag 8.30-10.30 &nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          type="radio"
                          className="radio"
                          id="time"
                          name="time"
                          value="thursday"
                        />{" "}
                        Torsdag 14.00-16.00
                      </div>
                    )}
                    <div>
                      <label htmlFor="problem">
                        Beskriv hvor mange rum/vinduer du ønsker gardin til og
                        hvis du allerede har gjort dig tanker om stil.
                      </label>
                      <textarea rows="10" name="problem"></textarea>
                    </div>
                    <div>
                      <label htmlFor="image_1">Billeder</label>
                      <input
                        type="file"
                        name="image_1"
                        id="image_1"
                        accept="image/*"
                      />
                      {!showMoreImages && (
                        <div className="consultancy-form-more-images-button">
                          <a onClick={moreImagesClick}>
                            + Tilføj flere billeder
                          </a>
                        </div>
                      )}
                      {showMoreImages && (
                        <div>
                          <input
                            type="file"
                            name="image_2"
                            id="image_2"
                            accept="image/*"
                          />
                          <input
                            type="file"
                            name="image_3"
                            id="image_3"
                            accept="image/*"
                          />
                          <input
                            type="file"
                            name="image_4"
                            id="image_4"
                            accept="image/*"
                          />
                          <input
                            type="file"
                            name="image_5"
                            id="image_5"
                            accept="image/*"
                          />
                        </div>
                      )}
                    </div>
                    <button type="submit">Submit</button>
                  </form>
                )}
                <div className="consultancy-contact">
                  Har du brug for hjælp med andet, kan du gå til&nbsp;
                  <Link to="/kontakt/">kontakt os siden</Link>.
                </div>
              </div>
            </div>

            <div className="image-col">
              <AspectRatio ratio="portrait">
                <Img src="https://images.prismic.io/anddrape/101e3a82-6957-4119-8932-7b52df37e9e6_Kontakt.jpg?auto=compress,format" />
              </AspectRatio>
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  )
}

export default Raadgivning
